import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Handler } from '@digital-magic/ts-common-utils'
import { Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { CommonControlProps } from '@controls/types'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer'
import { sendGoogleAdsConversion, sendSimpleFacebookEvent } from '@api/tracking'

export type SuggestVehicleFormControlsProps = Readonly<{
  onBtnSubmitClick: Handler<void>
  onBtnBackClick: Handler<void>
}> &
  CommonControlProps

export const SuggestVehicleFormControls: React.FC<SuggestVehicleFormControlsProps> = ({
                                                                                        disabledControls,
                                                                                        onBtnBackClick,
                                                                                        onBtnSubmitClick
                                                                                      }) => {
  const { t } = useTranslation()

  // Google Ads conversion tracking function
  const gtag_report_conversion = (url?: string): void => {
    const callback = (): void => {
      if (typeof url !== 'undefined') {
        window.location.href = url
      }
    }

    gtag('event', 'conversion', {
      'send_to': 'AW-11067064921/A9tUCIvn8ocYENmEmJ0p',
      'event_callback': callback,
    })
  }

  const handleSubmitClick = () => {
    gtag_report_conversion()
    sendGoogleAdsConversion('A9tUCIvn8ocYENmEmJ0p')
    sendSimpleFacebookEvent('lead')
    onBtnSubmitClick()
  }

  return (
    <ActionButtonsContainer fixedDirection justifyContentSpaceBetween noTopGap>
      <Button variant="text" color="darkBlue" startIcon={<ArrowBackIcon />} onClick={() => onBtnBackClick()}>
        {t('global.buttons.contactDetails')}
      </Button>
      <Button
        variant="contained"
        color="darkBlue"
        onClick={handleSubmitClick}
        endIcon={<ArrowForwardIcon />}
        disabled={disabledControls}
      >
        {t('global.buttons.sendApplication')}
      </Button>
    </ActionButtonsContainer>
  )
}
